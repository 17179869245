import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@fcr/azure-auth';
import { Endpoints } from '../../constants/endpoints';
import { catchError, map, of } from 'rxjs';
import { Router } from '@angular/router';
import { HPDataModel } from '../../models/hp-data.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrl: './listings.component.scss'
})

export class ListingsComponent implements OnInit{

  data: HPDataModel = new HPDataModel();
  loading: boolean = false;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, private title: Title){
  }

  ngOnInit(): void {
    this.getListings()
    this.title.setTitle("Mijnyouvia")
  }

  getListings() {
   this.loading = true;
    this.http.get<HPDataModel>(Endpoints.GET_LISTING_IDS()).pipe(
      map(data => {
        console.log('We got this data', data);
        if(data.count > 0 )
          this.data = data;
        this.loading = false;
      }),
      catchError(error => {
        if(error.status == 401){
          this.auth.localSignOut();
          this.router.navigateByUrl('/login');
        }
        this.loading = false;
        console.error('Error fetching listings:', error);
        return of(null); 
      })
    ).subscribe();
  }

  listenForSignOut() {
    this.auth.loggedOut.subscribe(x => {
      this.router.navigateByUrl("login")
    })
  }
}
