import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Plausible, { EventOptions, PlausibleOptions } from 'plausible-tracker';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlausibleTrackingService {
  private trackEvent;
  private trackPageview;
  private enableAutoPageviews;
  private enableAutoOutboundTracking;

  constructor(private translate: TranslateService) {
    const currentLang = this.getLang();
    console.log(currentLang);

    const plausibleOptions = {
      domain: environment.Plausible[currentLang]['domain'],
      apiHost: environment.Plausible[currentLang]['apiHost'],
      trackLocalhost: true
    };

    const plausible = Plausible(plausibleOptions);

    this.trackEvent = plausible.trackEvent;
    this.trackPageview = plausible.trackPageview;
    this.enableAutoPageviews = plausible.enableAutoPageviews;
    this.enableAutoOutboundTracking = plausible.enableAutoOutboundTracking;

    // enable automatic pageview tracking (optional)
    this.enableAutoPageviews();
  }

  public trackCustomEvent(eventName: string, eventData?: EventOptions, plausibleOptions?: PlausibleOptions) {
    this.trackEvent(eventName, eventData, plausibleOptions);
  }

  public trackCustomPageview(eventData?: EventOptions) {
    console.log(eventData);
    this.trackPageview(eventData);
  }

  public trackOutboundLinks(targetNode?: Node, observerInit?: MutationObserverInit) {
    return this.enableAutoOutboundTracking(targetNode, observerInit);
  }

  private getLang() {
    const currentLang = this.translate.currentLang || this.translate.getDefaultLang();
    return currentLang.replace("nl_", "");
  }
}