import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { Observable, ReplaySubject, Subject, catchError, debounceTime, map, of, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CountriesModalComponent, CountriesModalData } from './countries-modal/countries-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { openingHoursValidator } from './opening-hours/opening-hours.component';
import { Endpoints } from '../../../../constants/endpoints';
import { Loader } from '@googlemaps/js-api-loader/dist'
import { COUNTRY_UNDEFINED, COUNTRIES } from '../../../../constants/countries.enum';
import { CountryModel } from '../../../../models/country.model';
import { FormDataModel, OpeningHoursItem } from '../../../../models/form-data.model';
import { HeadingModel } from '../../../../models/heading.model';
import { PlausibleTrackingService } from '../../../../services/plausible-tracking.service';

const googleLoader = new Loader({
  apiKey: "AIzaSyBqmblPlYT657Nucv4SnrbtWHouBHcuEZ4",
  version: "weekly",
  libraries: ["places"]
});

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {
  showErrors: boolean = false;
  loading: boolean = false;
  showForm: boolean = true;
  showLoadError: boolean = false;
  showSummary: boolean = false;
  headings: HeadingModel[] = [];
  countries: CountryModel[] = [];
  primaryCountry: CountryModel = COUNTRY_UNDEFINED;

  enterpriseNumberVisible: boolean = true;
  kvkNumberVisible: boolean = true;

  enterpriseNumberCtrl: FormControl;
  kvkNumberCtrl: FormControl;

  // ask for user code delivered by email
  showAskForCodeForm: boolean = false;
  askForCodeForm: FormGroup;
  // .ask for user code delivered by email

  summaryText: string = "";

  form: FormGroup;  
  headingIdCtrl: FormControl = new FormControl(null, [Validators.required]);
  headingCtrl: FormControl = new FormControl(null, [Validators.required]);
  fixedPhoneNumberCtrl: FormControl = new FormControl(null, [phoneRequiredValidator(this)]);
  mobilePhoneNumberCtrl: FormControl = new FormControl(null, [phoneRequiredValidator(this)]);

  addressStreetCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressStreetNumberCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressStreetDoorCtrl: FormControl<string | null> = new FormControl(null);
  addressCityCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressDistrictCtrl: FormControl<string | null> = new FormControl(null);
  addressPostalCodeCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressCountryCtrl: FormControl<string | null> = new FormControl(null, [Validators.required]);
  addressCountryNameCtrl: FormControl<string | null> = new FormControl(null);

  formData: FormDataModel = new FormDataModel();

  @ViewChild('headingSelect', { static: true }) 
  headingSelect: MatSelect | undefined;

  @ViewChild('addressAutocomplete', { static: true }) 
  addressAutocompleteElement: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('summary', { static: false })
  scrollToSummary: ElementRef | null = null;

  @Input("lid")
  listingId: string | null = null;

  @Input("code")
  listingCode: string | null = null;

  @Input("locale")
  currentLocale: string = "nl_nl";

  // text
  @Input("formTitle")
  formTitle: string | null = null;

  @Input("formText")
  formText: string | null = null;

  @Input("platform")
  platformName: string = "";
  // .text

  @Input("utm")
  utmTags: Array<any> = [];

  @Input("brand")
  brand: string = "Youvia";      

  @Input("leadCampaign")
  leadCampaign: string = "";      

  @Input("leadSource")
  leadSource: string = "";      
  
  @Input("instance")
  instance: string = "NL"; 

  @Input("askForCode")
  askForCode: string = "false";

  @Output("loadError") 
  loadError: EventEmitter<any> = new EventEmitter();

  @Output("saveError") 
  saveError: EventEmitter<any> = new EventEmitter();

  @Output("subscribed") 
  subscribed: EventEmitter<FormDataModel> = new EventEmitter();

  @Output("replaceMessage")
  replaceMessage: EventEmitter<any> = new EventEmitter();

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // headings
  public headingFilterCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredHeadings: ReplaySubject<HeadingModel[]> = new ReplaySubject<HeadingModel[]>(1);

  // address
  autocompleteService: google.maps.places.AutocompleteService | undefined;  
  placesService: google.maps.places.PlacesService | undefined;
  addressSuggestOptions: Array<google.maps.places.AutocompletePrediction> = [];
  addressSearchCtrl: FormControl<string | null> = new FormControl(null)
  addressCountry: CountryModel = COUNTRY_UNDEFINED;
  autocomplete: google.maps.places.Autocomplete | undefined;

  // auth
  submited = false;
  identityId: string | null = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    public translate: TranslateService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private plausible: PlausibleTrackingService
    ){ 
    
    this.matIconRegistry
        .addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/close.svg`));
    this.matIconRegistry
        .addSvgIcon('external-link', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/external-link.svg`));  
    this.matIconRegistry
        .addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl(`${Endpoints.SPA_ASSETS()}/icons/add.svg`)); 


    // console.log("overlayContainer", overlayContainer);

    // ask for user code delivered by email
    this.askForCodeForm = new FormGroup({
      // code value
      codeFromEmail: new FormControl(null, [Validators.required])
    });
    // ask for user code delivered by email

    this.enterpriseNumberCtrl = new FormControl(null, [Validators.required], [vatAsyncValidator(http)]);
    this.kvkNumberCtrl = new FormControl(null, [Validators.required]);  

    this.form = new FormGroup({

      // main
      listingId: new FormControl(),
      enterpriseNumber: this.enterpriseNumberCtrl,
      kvkNumber: this.kvkNumberCtrl,
      organisationName: new FormControl(null, [Validators.required]),
      headingId: this.headingIdCtrl,

      // address
      addressSearch: this.addressSearchCtrl,
      addressStreet: this.addressStreetCtrl,
      addressStreetNumber: this.addressStreetNumberCtrl,
      addressStreetDoor: this.addressStreetDoorCtrl,
      addressCity: this.addressCityCtrl,
      addressDistrict: this.addressDistrictCtrl,
      addressPostalCode: this.addressPostalCodeCtrl,
      addressCountry: this.addressCountryCtrl,    
  

      // opening hours
      openingHours: new FormControl<OpeningHoursItem[]>(new Array<OpeningHoursItem>(), [openingHoursValidator()]),
      openingHoursAdditionalInfo: new FormControl(null),

      // contacts
      email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
      fixedPhoneNumber: this.fixedPhoneNumberCtrl,
      mobilePhoneNumber: this.mobilePhoneNumberCtrl,
      website: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      webshop: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      facebook: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?facebook\.com\/[\\w\\.\\-]+\\/?')]),
      instagram: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?instagram\.com\/[\\w\\.\\-]+\\/?')]),
      linkedIn: new FormControl(null, [Validators.pattern('https:\/\/(www\.)?linkedin\.com\/(in|company)/[\\w\\.\\-]+\\/?')]),
    });

    this.headingIdCtrl.valueChanges.subscribe((val : string) => {
      this.setHeadingById(val);
    })

    this.headingCtrl.valueChanges.subscribe((val : HeadingModel) => {
      this.headingIdCtrl.setValue(val?.headingId, { emitEvent: false });
    })
  }

  getTranslatedText(key: string): string {
    const translatedText = this.translate.instant(key);
    const platformName = this.translate.instant("summary.platformName");
    return translatedText.replaceAll('{0}', platformName);
  }

  setHeadingById(id: any) {
    this.formData.heading = this.headings.filter(h => h.headingId == id)?.shift() ?? null;    
    this.headingCtrl.setValue(this.formData.heading);
    // console.log("setHeadingById", id, this.formData.heading);
  }

  isFieldValid(name: string): boolean {
    const fieldCtrl = this.form.get(name);
    // console.log("isFieldValid", name, fieldCtrl, fieldCtrl?.valid, (!this.showErrors && (!fieldCtrl || fieldCtrl?.valid || fieldCtrl?.untouched)));
    return !this.showErrors && (!fieldCtrl || fieldCtrl?.valid || fieldCtrl?.untouched);
  }

  // ask for user code delivered by email
  switchFormsAndPreloadData() {
    this.showAskForCodeForm = false;
    this.showForm = true;
    this.preloadFormData();
    this.replaceMessage?.emit();
  }

  handleUTMTags() {
    if(this.utmTags.length === 0) {
      this.utmTags = [
        {code: "campaign", value: this.leadCampaign},
        {code: "source", value: "groupjoos"},
        {code: "medium", value: "letter"}
      ];
    }

    return this.utmTags;
  }

  addLeadSource() {
    if(this.leadSource.length === 0) {
      if (this.leadCampaign.includes("CYB")) {
          this.leadSource = "Claim Your Business";
      } 
      if (this.leadCampaign.includes("AYB")) {
          this.leadSource = "Add Your Business";
      }
    }
  }

  openFormWithUserCode() {
    //console.log("open form with user code");
    const userCodeValue = this.askForCodeForm.get('codeFromEmail')?.value ?? "";
    
    if(userCodeValue.trim() !== "") {
      this.listingCode = userCodeValue;
      this.utmTags = this.handleUTMTags();
      this.switchFormsAndPreloadData();
    }
  }

  openEmptyForm() {
    //console.log("open empty form");
    this.switchFormsAndPreloadData();
  }

  handleDisplayForm() {
    const askForCode = (this.askForCode === "true");
    const locale = this.currentLocale;

    this.setLocale(this.translate.currentLang);

    this.addLeadSource();

    if (askForCode) {
      this.showAskForCodeForm = true;
      this.showForm = false;
    } else {
      this.preloadFormData();
    }
  }

  getInputErrorFor(controlName: string): string {
    const errors = this.getInputErrorsFor(controlName);
    if (!errors || errors.length == 0)
        return "";
    return errors[0];
  }

  getInputErrorsFor(controlName: string): Array<string> {
    const errors = new Array<string>();
    
    if (!controlName)
      return errors;

    const controlErrors = this.askForCodeForm.get(controlName)?.errors;
    if (!controlErrors)
      return errors;

    // collect errors
    Object.keys(controlErrors).forEach(keyError => {
      errors.push(`askForCodeForm.${controlName}.${keyError}`)
    });

    // default
    return errors;
  }

  getAddressErrorFor(controlName: string): string {
    const errors = this.getAddressErrorsFor(controlName);
    if (!errors || errors.length == 0)
        return "";
    return errors[0];
  }

  getAddressErrorsFor(controlName: string): Array<string> {
    const errors = new Array<string>();
    
    if (!controlName)
      return errors;

    const controlErrors = this.form.get(controlName)?.errors;
    if (!controlErrors)
      return errors;

    // collect errors
    Object.keys(controlErrors).forEach(keyError => {
      errors.push(`address.${keyError}`)
    });

    // default
    return errors;
  }

  clearInputValue(controlName: string) {
    var ctrl = this.askForCodeForm.get(controlName);
    if (!ctrl) return;
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }
  // .ask for user code delivered by email

  setAttrToFormData() {
    this.formData.leadCampaign = this.leadCampaign;
    this.formData.leadSource = this.leadSource;
    this.formData.brand = this.brand;
    this.formData.utmTags = this.utmTags;
  }

  preloadFormData() {
    this.loading = true;

    const id = this.listingId ?? "";
    const code = this.listingCode ?? "";
    const instance = this.instance ?? "BE"; 

    if (id || code) {
      //console.log('preload, loading', id, code, instance);
      const url = Endpoints.LISTING(id);
      this.http.get<FormDataModel>(url).subscribe({
        next: (res) => {
          // res.mobilePhoneNumber = "+323434242323";
          // res.fixedPhoneNumber = "+32222222222"
          this.formData = res;      
          this.form.patchValue(this.formData);
          // console.log('preload, fetched', res, this.form.getRawValue());    
          if (this.formData.addressCountry) {                  
            this.setCountry(this.formData.addressCountry);
          }
          
          this.form.markAllAsTouched();

          // this.formData.instance = this.instance;
          this.setAttrToFormData();

          this.loading = false;
        }, 
        error: (err) => { 
          // console.log('Preloading failed!', err)
          this.showForm = false;
          this.showLoadError = true;
          this.loading = false;
          this.loadError?.emit(err);
        }, 
        complete: () => { 
          this.loading = false;
        }
      });
    }
    else {
      // console.log('preload, skipped - no id|code')
      this.formData ??= new FormDataModel();      
      this.setAttrToFormData();

      this.loading = false;
    }
  }

  ngOnInit() {

    // console.log('INIT', this)
    //this.initHeadingLookup();
    this.initGoogleLookup();

    // this method determines whether to display form for ask user for a code or to preload form data
    this.handleDisplayForm();

    this.translate.onLangChange.subscribe(x => {
      this.setHeadings()
    })
  }

  initHeadingLookup() {
    // load the initial headings list
    this.filteredHeadings.next(this.headings.slice());

    // listen for headings search field value changes
    this.headingFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHeadings();
      });      
  }

  ngOnChanges() {
    // needs update headings when lang is changed
    //this.setHeadings();
    this.setLocale(this.translate.currentLang);
    
    // needs update primaryCountry when lang is changed
    this.primaryCountry = this.getCountry(this.currentLocale);
  }

  initGoogleLookup() {
    googleLoader.importLibrary("places").then(l => {

      // if (this.addressAutocompleteElement) {
      //   this.autocomplete = new l.Autocomplete(this.addressAutocompleteElement.nativeElement, {types: ['geocode']})
      //   this.autocomplete.setFields(['address_component']);
      //   this.autocomplete.addListener('place_changed', () => {
      //     this.fillInAddress();
      //   });
      //   if (this.addressCountry) {
      //     this.autocomplete.setComponentRestrictions({country: this.addressCountry.code.toLocaleLowerCase()});
      //   }        
      // }

      this.autocompleteService = new l.AutocompleteService();
      this.placesService = new l.PlacesService(document.createElement('div'));
      // console.log('placesService initialized?', this.placesService)
      
      // console.log('autosuggest initialized?', this.autocompleteService)
      
      // listen for address search field value changes
      this.addressSearchCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .pipe(debounceTime(300))
        .subscribe((query) => {
          if (query && query.length > 3 && this.autocompleteService) {
            console.log("auto complete, request", query);
            this.getPlacePredictions(query).then(res => {
              if (res) {
                this.addressSuggestOptions = res;
              }
              else {
                this.addressSuggestOptions = [];                
              }              
              // console.log("auto complete, promise", res);
            });
          }
        }, 
        () => {}, 
        () => {}
        );

    });
  }

  updatePhonesValidation() {
    this.fixedPhoneNumberCtrl.updateValueAndValidity();
    this.mobilePhoneNumberCtrl.updateValueAndValidity();
  }

  fillInAddress() {

    // console.log('fillInAddress', this.autocomplete, this.autocomplete?.getPlace())
    if (!this.autocomplete)
      return;

    var place = this.autocomplete.getPlace();
    // console.log('fillInAddress:place', place);
    // update address elements
    
    this.addressStreetCtrl.setValue(this.getPlacePart(place, "route"));
    this.addressStreetNumberCtrl.setValue(this.getPlacePart(place, "street_number"));
    this.addressStreetDoorCtrl.setValue(this.getPlacePart(place, "premise"));
    this.addressCityCtrl.setValue(this.getPlacePart(place, "locality"));
    this.addressDistrictCtrl.setValue(this.getPlacePart(place, "neighborhood"));
    this.addressPostalCodeCtrl.setValue(this.getPlacePart(place, "postal_code"));  
  }

  getPlacePart(place: google.maps.places.PlaceResult, key: string) : string | null {
    var res = place.address_components?.filter(x => x.types.indexOf(key) > -1).shift()?.long_name
      ?? null;
    //console.log("getPlacePart", place, key, res);
    return res;
  }

  getPlacePredictions(query: string): Promise<Array<google.maps.places.AutocompletePrediction> | null> {
    return new Promise((resolve, reject) => {
        this.autocompleteService?.getPlacePredictions({
            types: [],            
            input: query,            
            componentRestrictions: { country: this.addressCountry.code }
        }, (predictions, status) => {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                console.log("getPlacePredictions", predictions)
                resolve(predictions);
            } else {
                reject(status);
            }
        });
    });
  } 

  // getPlace(placeId: string) : Promise<google.maps.places.PlaceResult | null> {
  //   return new Promise((resolve, reject) => {
  //       this.placesService?.getDetails({
  //         placeId: placeId
  //       }, (placeResult, status) => {
  //           if (status == google.maps.places.PlacesServiceStatus.OK) {
  //               resolve(placeResult);
  //           } else {
  //               reject(status);
  //           }
  //       });
  //   });
  // }

  selectAddressFn = (prediction: google.maps.places.AutocompletePrediction) : string => {

    //console.log('selectAddressFn', this.autocompleteService, this.placesService, prediction)
    if (!this.placesService || !prediction?.place_id)
      return "";

    const request = {
      placeId: prediction.place_id,      
      fields: ["all"],
    };

    let address = "";
    // console.log('maps getDetails start', request);
    this.placesService.getDetails(request, (place: google.maps.places.PlaceResult | null, status: any) => {
      console.log('maps getDetails done', place, status);
      if (place) {
        this.addressStreetCtrl.setValue(this.getPlacePart(place, "route"));
        this.addressStreetNumberCtrl.setValue(this.getPlacePart(place, "street_number"));
        this.addressStreetDoorCtrl.setValue(this.getPlacePart(place, "premise"));
        this.addressCityCtrl.setValue(this.getPlacePart(place, "locality"));
        this.addressDistrictCtrl.setValue(this.getPlacePart(place, "neighborhood") ?? this.getPlacePart(place, "sublocality_level_1"));
        this.addressPostalCodeCtrl.setValue(this.getPlacePart(place, "postal_code"));
        address = place.formatted_address ?? "";
      }
    });

    return address;
  }

  addressSelected(place: any) {
  }

  ngAfterViewInit() {
    // this.setInitialValue();
  }
  
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  /**
  * Sets the initial value after the filteredBanks are loaded initially
  */
  protected setInitialValue() {
    this.filteredHeadings
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        if (this.headingSelect)
          this.headingSelect.compareWith = (a: any, b: any) => a && b && a.headingId === b.headingId;
      });
  }

  protected filterHeadings() {
    if (!this.headings) {
      return;
    }
    // get the search keyword
    let search = this.headingFilterCtrl.value;
    if (!search) {
      this.filteredHeadings.next(this.headings.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredHeadings.next(
      this.headings.filter(h => h.headingName.toLowerCase().indexOf(search) > -1)
    );
  }
  
  clearValue(name: string) {
    var ctrl = this.form.get(name);
    if (!ctrl) return;
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }

  openCountries() {
    const data: CountriesModalData = { showDialCode: true, showCountryCode: false, countries: this.countries };
    const config: MatDialogConfig = { data: data, width: "500px", position: { top: "5rem" } };
    const dialogRef = this.dialog.open(CountriesModalComponent, {...config, autoFocus: true});
    dialogRef.afterClosed().subscribe((country: CountryModel) => {
      if (country) {
        this.addressCountry = country;
        this.form.get("addressCountry")?.setValue(country.name);
        this.setCountry(country.code.toLocaleLowerCase());
      }
    })
    
  }


  getErrorForOpeningHours(controlName: string) : Observable<string> | null | undefined {


    if (!controlName)
      return null;

    const controlErrors = this.form.get(controlName)?.errors;
    if (!controlErrors)
      return null;

    let res: any= null;
    Object.keys(controlErrors).forEach(keyError => {
      const error = controlErrors[keyError];
      res = this.getOpeningHoursError(keyError, error);
    });

    return res;

  }

  getOpeningHoursError(keyError: string, error: any): string {
    if (error['weekDay']) {      
      error['dayName'] = this.translate.instant(`weekdays.${error['weekDay']?.toLowerCase()}`);
    }
    return this.translate.instant(`openingHours.${keyError}`, error);
  }


  getErrorFor(controlName: string): string {
    const errors = this.getErrorsFor(controlName);
    if (!errors || errors.length == 0)
        return "";
    const error = errors[0];
    if (error.indexOf("overlap") > -1 ) {
      
    }
    return error;
  }

  getErrorsFor(controlName: string): Array<string> {

    const errors = new Array<string>();
    
    if (!controlName)
      return errors;

    const controlErrors = this.form.get(controlName)?.errors;
    if(controlName = "enterpriseNumber")
      console.log("controlErrors - enterpriseNumber", controlErrors)
    if (!controlErrors)
      return errors;

    // collect errors
    Object.keys(controlErrors).forEach(keyError => {
      errors.push(`fields.${controlName}.${keyError}`)
    });

    // default
    return errors;
  }

  getAllErrors(): Array<string> {
    const errors = new Array<string>();
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors = this.form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => { 
          // console.log("getAllErrors", key, keyError);
          if (keyError == "overlapSegment" || keyError == "overlapDay") {
            // Specific error handling for opening hours errors, where aditional values requierd
            const error = controlErrors[keyError];
            errors.push(this.getOpeningHoursError(keyError, error));
          } else {
            errors.push(`fields.${key}.${keyError}`);
          }       
        });
      }
    });
    return errors;
  }

  setCountries() {
    const fr = this.currentLocale.slice(-2) == "fr";
    this.countries = COUNTRIES.map(c => ({ name: (fr)?c.name_fr:c.name_nl??"", code: c.code??"", diallingCode: c.diallingCode??"" }));
  }

  setHeadings() {
    this.http
      .get(Endpoints.FETCH_HEADINGS(this.translate.currentLang.slice(-2).toUpperCase()))
      .subscribe((items: Array<HeadingModel> | any) => {
        this.headings = items;
        this.initHeadingLookup();
        this.setInitialValue();
        this.setHeadingById(this.formData.headingId)
      });
  }

  setCountry(code: string) {
    // console.log('setting country', code, this.addressCountry == COUNTRY_UNDEFINED, this.addressCountry)
    const country = this.getCountry(code);

    if (country == COUNTRY_UNDEFINED)
      return;

    this.addressCountry = this.getCountry(code);
    this.addressCountryNameCtrl.setValue(this.addressCountry.name, { emitEvent: true });
    this.addressCountryCtrl.setValue(this.addressCountry.code, { emitEvent: false });
    this.autocomplete?.setComponentRestrictions({ country: code.toLocaleLowerCase() });  
  }

  getCountry(code: string): CountryModel{
    code = code.substring(0, 2).toLocaleLowerCase();

    return this.countries
      .filter(c => c.code.toLocaleLowerCase() == code).shift() 
      ?? COUNTRY_UNDEFINED; 
  }

  setLocale(localeCode : string) {
    if (localeCode != "nl_nl" &&
        localeCode != "be_fr" &&
        localeCode != "nl_en"  
      ) localeCode = "nl_nl"; // <-- default
  
    // console.log('setting locale', localeCode);

    this.translate.use(localeCode);
    this.currentLocale = localeCode;
    this.setHeadings(); 
    this.setCountries(); 
    this.setCountry(localeCode.substring(0, 2));
    if (this.formData)
      this.formData.mainLanguage = localeCode.substring(0, 2);

    // update form based on locale
    if (localeCode.substring(0, 2) == "nl") {
      this.kvkNumberVisible = true;
      this.kvkNumberCtrl.addValidators([Validators.required]);
      this.kvkNumberCtrl.addAsyncValidators([]);
      this.kvkNumberCtrl.updateValueAndValidity();
      
      this.enterpriseNumberVisible = false;
      this.enterpriseNumberCtrl.clearValidators();
      this.enterpriseNumberCtrl.clearAsyncValidators();
      this.enterpriseNumberCtrl.updateValueAndValidity();

    }
    else {
      this.kvkNumberVisible = false;
      this.kvkNumberCtrl.clearValidators();
      this.kvkNumberCtrl.clearAsyncValidators();
      this.kvkNumberCtrl.updateValueAndValidity();

      this.enterpriseNumberVisible = true;
      this.enterpriseNumberCtrl.addValidators([Validators.required]);
      this.enterpriseNumberCtrl.addAsyncValidators([vatAsyncValidator(this.http)]);
      this.enterpriseNumberCtrl.updateValueAndValidity();
    }

  }

  scrollToTop(elementRef: ElementRef | null): void { 
    if (elementRef?.nativeElement) {
      elementRef.nativeElement.scrollIntoView({ block: 'start' });
    }
  }

  handleShowSummary(): void {
    this.showForm = false;
    this.showSummary = true;

    // Trigger change detection to ensure summary is rendered
    this.cdr.detectChanges();

    this.scrollToTop(this.scrollToSummary);
  }

  postData() {

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    // proceed with submiting the data
    this.showErrors = false;
    var data = this.form.getRawValue();    
    data.leadCampaign = this.leadCampaign;
    data.leadSource = this.leadSource;
    data.mainLanguage = this.currentLocale.slice(-2);
    data.utmTags = this.utmTags;
    data.brand = this.brand;
    data.identityId = this.identityId;
    console.log("FORM-DATA", data);

    // instantly show summary
    this.handleShowSummary();
    this.formData = data;

    //this.scrollToTop();

    //this.loading = true;
    const url = Endpoints.LISTING(this.listingId);

    this.http.post(url, data).subscribe({
      next: (res) => {
        console.log('POST-OK', res);
        this.loading = false;
        this.formData = data;
        this.subscribed?.emit(data);
        this.submited = true;
        // track
        this.plausible.trackCustomEvent("SubmiEditFormSuccess", { props: { lid: this.listingId }});
      }, 
      error: err => {
        this.loading = false;
        this.saveError?.emit(err);
        console.log('POST-ERROR', err);
        // track
        this.plausible.trackCustomEvent("SubmiEditFormError", { props: { lid: this.listingId }});
      }, 
      complete: () => {
        console.log('POST-DONE!');
        this.loading = false;
      }
    });

  }

  toJson(obj: any) {
    return JSON.stringify(obj);
  }

  getHeadingForSummaryPage() {
    let heading = this.headings.find((x:HeadingModel) => {return x.headingId === this.formData.headingId});
    return heading.headingName
  }
}

export function vatAsyncValidator(http: HttpClient) : AsyncValidatorFn {
  return  (control: AbstractControl) : Observable<ValidationErrors | null> => {
    
    const err = { wrongVat: true };
    const vat = control.value;
    if (!vat) {
      console.log("VAT CHECK, no value -> end")
      return of({ required: true})
    }
    
    if (!/[A-Z]{2}\d+/.test(vat)) {
      console.log("VAT CHECK, no valid format -> end")
      return of(err);
    }
    
    const url = Endpoints.VALIDATE_VAT();
    return http
      .post<any>(url,{ "vatNumber": `${vat}` })
      .pipe(
        map((res) => {
          console.log("VAT CHECK, http response", res)
          return (res?.isValid == true)
            ? null
            : err
        }),
        catchError((error) => {
          return of(err)
        })
      );
  }
}

export function phoneRequiredValidator(component: FormComponent): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {

      const mobileValue = component.mobilePhoneNumberCtrl?.value;
      const fixedValue = component.fixedPhoneNumberCtrl?.value;

      // console.log("phoneRequiredValidator", control, fixedValue, mobileValue);

      if (mobileValue || fixedValue) {
        // if (component.mobilePhoneNumberCtrl == control)
        //   component.fixedPhoneNumberCtrl.updateValueAndValidity({ emitEvent: false });
        // if (component.fixedPhoneNumberCtrl == control)
        //   component.mobilePhoneNumberCtrl.updateValueAndValidity({ emitEvent: false });
        // console.log("phoneRequiredValidator: OK", control, fixedValue, mobileValue);
        return null;
      }
                
      // console.log("phoneRequiredValidator: ERROR", control, fixedValue, mobileValue);
      return { "phoneRequired": true }

  }  
}