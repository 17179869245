import { environment } from "../../environments/environment";

//For local testing, please change environment.ts file
const BASE = environment.Endpoints.base
const BASE_API = environment.Endpoints.baseApi
export const AUTH_URL = environment.Endpoints.authUrl

export const BASE_URL = `${BASE_API}/api`;

export class  Endpoints {
    
    static SPA_ASSETS = () => `${BASE}/assets`;
    
    static GET_LISTING_IDS = () => `${BASE_URL}/ListingIndex`;

    static LISTING = (id: string) => {
        //console.log(countryCode);
        return `${BASE_URL}/Listing/${id}`;
    };
    static VALIDATE_VAT = () => `${BASE_URL}/VatValidator`;

    static FETCH_HEADINGS = (lang: string) => {
        return `${BASE_URL}/Heading?language=${lang}`;
    }
}