import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { MultiLocaleTranslateService } from '../../../../services/multilocale-translate.service';
import { ListingDataModel } from '../../../../models/listing.model';
import { PlausibleTrackingService } from '../../../../services/plausible-tracking.service';

@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrl: './listing-card.component.scss'
})
export class ListingCardComponent implements OnInit{
  @Input("listing")
  listing: ListingDataModel

  @Input("toogleEdit")
  toggleEdit: boolean = false;

  seeOnlineUrl: string;

  constructor(
    private translate: MultiLocaleTranslateService,
    private plausible: PlausibleTrackingService){}

  ngOnInit(): void {
    this.setSeeOnlineUrl()
  }

  setSeeOnlineUrl() {
      this.seeOnlineUrl = environment.SeeOnlineUrl.nl.replace(":id", this.listing.id)
  }

  handleEditClick() {
    // track
    this.plausible.trackCustomEvent("EditListingClick", { props: { lid: this.listing.id } });
    // handle edit toggle
    this.toggleEdit = !this.toggleEdit;
  }

  trackSeeOnlineClick() {
    this.plausible.trackCustomEvent("SeeOnlineClick", { props: { lid: this.listing.id } });
  }
}
