import { Component, OnInit } from '@angular/core';
import { MultiLocaleTranslateService } from '../../services/multilocale-translate.service';

type CurrentLocale = 'nl' | 'fr' | 'en';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit{
  languages: Array<CurrentLocale>;
  filteredLanguages: Array<CurrentLocale>;
  selectedLanguage: CurrentLocale;
  isOpen: boolean = false;

  constructor(public translate: MultiLocaleTranslateService) {
    
  }

  ngOnInit(): void {
    if(this.translate.currentLang.includes("be_"))
      this.languages = this.languages = ['nl', 'fr', 'en'];
    else
      this.languages = this.languages = ['nl', 'en'];

    this.selectedLanguage = this.translate.currentLang?.replaceAll("be_","").replaceAll("nl_","") as CurrentLocale;
    this.filteredLanguages = this.languages.filter(lang => lang !== this.selectedLanguage);
  }

  setSelectedLanguage(locale: CurrentLocale): void {
    this.translate.useLang(locale)  
    this.selectedLanguage = locale as CurrentLocale;
    this.filteredLanguages = this.languages.filter(lang => lang !== this.selectedLanguage);
  }

  handleOpenLanguageSwitch(): void {
    this.isOpen = !this.isOpen;
  }

  handleSelectLanguage(selectedLocale: CurrentLocale): void {
    this.setSelectedLanguage(selectedLocale);
    this.filteredLanguages = this.languages.filter(lang => lang !== this.selectedLanguage);
    this.handleOpenLanguageSwitch();
  }
}