import { Component, OnInit } from '@angular/core';
import { AuthService } from '@fcr/azure-auth';
import { MultiLocaleTranslateService } from './services/multilocale-translate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'scale-up.SPA';
  isLoginPage: boolean = false;

  constructor(
    private translate: MultiLocaleTranslateService, 
    private auth: AuthService,
    private router: Router){}

  ngOnInit(): void {
    this.translate.setLocaleByDomain('nl_');
    this.auth.autoLogin();

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.isLoginPage = this.checkLoginPage(event.url);
      }
    });
  }

  checkLoginPage(url: string): boolean {
    return url === '/login';
  }
}
