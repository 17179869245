export const environment = {
    EnvironmentName: "DEV",
    Endpoints: {
        base: "https://dev-nl-scale-up.dev3.fcrtech.cz",
        baseApi: "https://dev-nl-scale-up.dev3.fcrtech.cz",
        authUrl: "https://dev-fcrauth.dev4.fcrtech.cz"
    },
    MFE: {
        ListingForm: {
            name: 'listing-form',
            path: '//dev-mfe.dev4.fcrtech.cz/listing-form.js'
        }
    },
    SeeOnlineUrl: {
        be: "https://dev-goudengids-be.dev4.fcrtech.cz/bedrijf/-/:id/-",
        nl: "https://dev-goudengids-nl.dev4.fcrtech.cz/nl/bedrijf/-/:id/-"
    },
    Plausible: {
        nl: {
            domain: "uat-scaleup-nl.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        },
        fr: {
            domain: "uat-scaleup-nl.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        },
        en: {
            domain: "uat-scaleup-nl.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        }
    },
};
