import { Component, OnInit } from '@angular/core';
import { AuthService } from '@fcr/azure-auth';
import { MultiLocaleTranslateService } from './services/multilocale-translate.service';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/browser";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'scale-up.SPA';
  isLoginPage: boolean = false;

  constructor(
    private translate: MultiLocaleTranslateService, 
    private auth: AuthService,
    private router: Router){}

  ngOnInit(): void {
    Sentry.init({
      dsn: environment.Sentry.dsn,
      environment: environment.EnvironmentName,
      tracesSampleRate: environment.Sentry.tracesSampleRate,
      replaysSessionSampleRate: environment.Sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: environment.Sentry.replaysOnErrorSampleRate,
      enabled: environment.Sentry.isEnabled,
      integrations: [
        Sentry.replayIntegration({
          networkDetailAllowUrls: ['https://uat-mfe.dev4.fcrtech.cz/api/Listing', 'https://uat-mfe.dev4.fcrtech.cz/api/listingOwner'],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        })
      ]

    });

    this.translate.setLocaleByDomain('nl_');
    this.auth.autoLogin();

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.isLoginPage = this.checkLoginPage(event.url);
      }
    });
  }

  checkLoginPage(url: string): boolean {
    return url === '/login';
  }
}
