<app-sticky-element>
    <div class="su-header">
        <div class="su-container">
            <div class="flex">
                <!-- logo -->
                <a [routerLink]="'/'" 
                    [ariaLabel]="'GoldenPages' | translate"
                    class="flex md:flex-row items-baseline">
                    <app-icon [icon]="'logo_symbol_border'" svgClass="logo" class=""></app-icon>
                    <app-icon [icon]="'logo_txt_nl'" svgClass="logo-text"></app-icon>
                </a>

                <!-- form -->
                <div class="flex gap-2 ml-auto">
                    <!-- links -->
                    <div class="flex gap-4 items-center">
                        <a *ngIf="signOutButtonEnabled"
                            (click)="signOut()" 
                            class="btn btn--large login-btn signout-btn">{{ "header.signOut" | translate }}</a>
                    </div>
                </div>
            </div>
        </div><!-- /container -->
    </div>
</app-sticky-element>